import React, { useState, useEffect, useCallback } from "react";
import background from "../assets/background.jpg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PropertyCard from "../components/PropertyCard";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getProperty,
  getPropertyDetail,
  apiFilterProperty,
  getZipCode,
} from "../libs/api.js";
import { debounce } from "../utils/debounceUtils.js";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [propertyData, setPropertyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const pageLimit = 28;
  const [pageStart, setPageStart] = useState(1);
  const [zipcodeSuggestions, setZipcodeSuggestions] = useState([]);

  const [filterProperty, setfilterProperty] = useState({
    priceMin: "",
    priceMax: "",
    bedroomMin: "",
    bedroomMax: "",
    propertyType: "",
    zipcode: "",
    radius: "",
  });

  const handleOnChangeProperty = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if ((name === "priceMin" || name === "bedroomMin") && value < 0) return;
    if (
      (name === "priceMax" && (value < 0 || value < filterProperty.priceMin)) ||
      (name === "bedroomMax" && (value < 0 || value < filterProperty.bedroomMin))
    ) {
      return;
    }

    if (name === "bedroomMin" || name === "bedroomMax") {
      setBHasValue(value !== "");
    }
    if (name === "priceMin" || name === "priceMax") {
      setHasValue(value !== "");
    }

    setfilterProperty({
      ...filterProperty,
      [name]: value,
    });
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenb, setIsOpenb] = useState(false);
  const [hasvalue, setHasValue] = useState(false);
  const [bhasvalue, setBHasValue] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (isOpenb) setIsOpenb(false);
  };

  const toggleDropdownb = () => {
    setIsOpenb(!isOpenb);
    if (isOpen) setIsOpen(false);
  };

  const searchPropertyByFilter = async (filters) => {
    setSearching(true);
    setLoading(true);
    setZipcodeSuggestions([]);
    setIsOpen(false);
    setIsOpenb(false);
    const { priceMin, priceMax, bedroomMin, bedroomMax, propertyType, zipcode, radius } = filters;
    const response = await apiFilterProperty(
      priceMin,
      priceMax,
      bedroomMin,
      bedroomMax,
      propertyType,
      null, 
      pageStart,
      pageLimit,
      zipcode,
      radius
    );

    let result = [];
    const data = response["PropertyIds"] || [];
    for (let i = 0; i < data.length; i++) {
      const property = data[i];
      const id = property.PropertyId;
      const propertyDetail = await getPropertyDetail(id);
      result = [...result, propertyDetail.Property];
    }
    result = result.filter((item)=>!!item);


    setPropertyData(result);
    setLoading(false);
    setSearching(false); 
  }


  const handleOnSearchClick = async () => {
    
    const { priceMin, priceMax, bedroomMin, bedroomMax, propertyType, zipcode, radius } = filterProperty;

    await searchPropertyByFilter(filterProperty);
    const queryParams = new URLSearchParams();
    if (priceMin) queryParams.set('priceMin', priceMin);
    if (priceMax) queryParams.set('priceMax', priceMax);
    if (bedroomMin) queryParams.set('bedroomMin', bedroomMin);
    if (bedroomMax) queryParams.set('bedroomMax', bedroomMax);
    if (propertyType) queryParams.set('propertyType', propertyType);
    if (zipcode) queryParams.set('zipcode', zipcode);
    if (radius) queryParams.set('radius', radius);

    console.log("Filtering properties with query params", queryParams.toString());
    navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
  };

  const clearOnSearchClick = async () => {
    setSearching(true);
    setLoading(true);
  
    setfilterProperty({
      priceMin: "",
      priceMax: "",
      bedroomMin: "",
      bedroomMax: "",
      propertyType: "",
      zipcode: "",
      radius: "",
    });
  
    setIsOpen(false);
    setIsOpenb(false);
    setHasValue(false);
    setBHasValue(false);
    setZipcodeSuggestions([]);
  
    await fetchProperty();
    navigate(`${location.pathname}`, { replace: true });

    setLoading(false);
    setSearching(false);
  };
  

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const priceMin = queryParams.get('priceMin');
    const priceMax = queryParams.get('priceMax');
    const bedroomMin = queryParams.get('bedroomMin');
    const bedroomMax = queryParams.get('bedroomMax');
    const propertyType = queryParams.get('propertyType');
    const zipcode = queryParams.get('zipcode');
    const radius = queryParams.get('radius');

    if (!!priceMax || !!priceMin || !!bedroomMin || !!bedroomMax || !!propertyType || !!zipcode || !!radius) {
      console.log("Query params found", queryParams.toString());
      const filters = {
        priceMin: priceMin || "",
        priceMax: priceMax || "",
        bedroomMin: bedroomMin || "",
        bedroomMax: bedroomMax || "",
        propertyType: propertyType || "",
        zipcode: zipcode || "",
        radius: radius || "",
      };
      setfilterProperty(filters);
      searchPropertyByFilter(filters);
    } else {
      fetchProperty();
    }
  }, [location]);


  const handleOnNextClick = () => {
    if (propertyData.length < pageLimit) {
      return;
    }
    const property = propertyData[propertyData.length - 1];
    setPageStart(property.PropertyId);
  };

  const fetchProperty = async () => {
    setLoading(true);
    const response = await getProperty(pageStart, pageLimit);
    let result = [];
    const data = response["PropertyIds"] || [];
    for (let i = 0; i < data.length; i++) {
      const property = data[i];
      const id = property.PropertyId;
      const propertyDetail = await getPropertyDetail(id);
      result = [...result, propertyDetail.Property];
    }
    result = result.filter((item)=>!!item);

    console.log(result);
    setPropertyData((prev) => {
      const uniqueProperties = new Map();
      [...prev, ...result].forEach((property) => {
        uniqueProperties.set(property.PropertyId, property);
      });
      return Array.from(uniqueProperties.values());
    });
    setLoading(false);
  };

  const onClickProperty = (id) => {
    navigate("/property-detail/" + id);
  };

  const fetchZipcodeSuggestions = async (value) => {
    const suggestions = await getZipCode(value);
    console.log("suggestions", suggestions);
    const zips = suggestions['Zips'].map((zip) => zip.PostCode);
    setZipcodeSuggestions(zips);
  };
  const debouncedFetchZipcodeSuggestions = useCallback(debounce(fetchZipcodeSuggestions, 300), []);

  const handleZipcodeChange = async (e) => {
    const value = e.target.value;
    console.log("value", value);

    setfilterProperty({
      ...filterProperty,
      zipcode: value,
    });
    if (value.length > 2) {
      debouncedFetchZipcodeSuggestions(value);
    } else {
      setZipcodeSuggestions([]);
    }
  };

  const handleZipcodeSelect = (zip) => {
    setfilterProperty({
      ...filterProperty,
      zipcode: zip,
    });
    setZipcodeSuggestions([]);
  };

  const handleOnChangeRadius = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log("value", name, value);
    setfilterProperty({
      ...filterProperty,
      [name]: value,
    });
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="fixed top-0 w-full z-50">
        <Navbar />
      </div>

      <div className="flex-grow pt-16">
        <div className="relative w-full h-96">
          <img
            src={background}
            alt="Background"
            className="object-cover w-full h-full"
          />
          <div className="absolute inset-0 flex justify-center items-center p-4">
            <div className="flex flex-col w-5/7 p-4 border rounded-lg shadow-lg bg-white">
              <div className="flex flex-wrap justify-center items-center gap-2">
                <div className="flex items-center gap-1">
                  <div className="relative">
                    <button
                      className={`btn btn-xs text-xs font-semibold ${
                        hasvalue ? "btn-primary" : ""
                      }`}
                      onClick={toggleDropdown}
                      disabled={searching}
                    >
                      $Budget
                    </button>
                    <div
                      className={`absolute z-10 w-max mt-2 ${
                        isOpen ? "block" : "hidden"
                      }`}
                    >
                      <div className="bg-base-100 shadow-xl p-4 rounded-md flex flex-col gap-2">
                        <input
                          type="number"
                          name="priceMin"
                          placeholder="Min"
                          className="input input-xs input-bordered w-16 text-xs filter-input"
                          onChange={handleOnChangeProperty}
                          value={filterProperty.priceMin}
                        />
                        <input
                          type="number"
                          name="priceMax"
                          placeholder="Max"
                          className="input input-xs input-bordered w-16 text-xs filter-input"
                          onChange={handleOnChangeProperty}
                          value={filterProperty.priceMax}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-1">
                  <div className="relative">
                    <button
                      className={`btn btn-xs text-xs font-semibold ${
                        bhasvalue ? "btn-primary" : ""
                      }`}
                      onClick={toggleDropdownb}
                      disabled={searching}
                    >
                      Bedrooms
                    </button>
                    <div
                      className={`absolute z-10 w-max mt-2 ${
                        isOpenb ? "block" : "hidden"
                      }`}
                    >
                      <div className="bg-base-100 shadow-xl p-4 rounded-md flex flex-col gap-2">
                        <input
                          type="number"
                          name="bedroomMin"
                          placeholder="Min"
                          className="input input-xs input-bordered w-16 text-xs filter-input"
                          onChange={handleOnChangeProperty}
                          value={filterProperty.bedroomMin}
                        />
                        <input
                          type="number"
                          name="bedroomMax"
                          placeholder="Max"
                          className="input input-xs input-bordered w-16 text-xs filter-input"
                          onChange={handleOnChangeProperty}
                          value={filterProperty.bedroomMax}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <span className="text-xs mx-1 font-semibold">
                    Property Type:
                  </span>
                  <select
                    placeholder="Property Type"
                    name="propertyType"
                    className="input input-xs input-bordered w-26 text-xs filter-select"
                    onChange={handleOnChangeProperty}
                    value={filterProperty.propertyType}
                    disabled={searching}
                  >
                    <option value="">All</option>
                    <option value="room">Room</option>
                    <option value="studio">Studio</option>
                    <option value="condo">Condo</option>
                    <option value="house">House</option>
                    <option value="shared">Shared</option>
                  </select>
                </div>

                <div className="mx-2 relative">
                  <span className="text-xs mx-1 font-semibold">Zip Code:</span>
                  <input
                    type="text"
                    placeholder="20059"
                    value={filterProperty.zipcode}
                    onChange={handleZipcodeChange}
                    className="input input-xs input-bordered w-20 text-xs filter-select"
                    disabled={searching}
                  />
                  {zipcodeSuggestions.length > 0 && (
                    <ul className="absolute z-10 w-20 bg-white border border-gray-300 rounded mt-1">
                      {zipcodeSuggestions.slice(0,8).map((zip) => (
                        <li
                          key={zip}
                          onClick={() => handleZipcodeSelect(zip)}
                          className="px-4 py-1 cursor-pointer hover:bg-gray-200 text-xs"
                        >
                          {zip}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div>
                  <span className="text-xs font-semibold">Zip code Radius:</span>
                  <select
                    placeholder="Radius"
                    name="radius"
                    className="input input-xs input-bordered w-26 text-xs filter-select"
                    onChange={handleOnChangeRadius}
                    value={filterProperty.radius}
                    disabled={!filterProperty.zipcode || searching}
                  >
                    <option value="">{"N/A"}</option>
                    <option value="1">{"<=1 mile"}</option>
                    <option value="10">{"<=10 miles"}</option>
                    <option value="50">{"<=50 miles"}</option>
                    <option value="">{">=50 miles"}</option>
                  </select>
                </div>
                <button
                  className="btn btn-sm btn-primary mx-1 text-xs"
                  onClick={handleOnSearchClick}
                  disabled={searching}
                >
                  Search
                </button>
                <button
                  className="btn btn-sm ml-3 text-xs"
                  onClick={clearOnSearchClick}
                  disabled={searching}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-6 lg:justify-start justify-center lg:pl-4 py-4 mb-4">
          {loading ? (
            Array.from({ length: pageLimit }).map((_, index) => (
              <div key={index} className="skeleton h-80 w-[340px]"></div>
            ))
          ) : propertyData.length > 0 ? (
            propertyData.map((item) => (
              <PropertyCard
                key={item.PropertyId}
                propertyDetail={item}
                onClick={() => onClickProperty(item.PropertyId)}
              />
            ))
          ) : (
            <div className="flex justify-center items-center w-full h-full">
              <h2 className="text-2xl font-bold">No property found ... </h2>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <nav>
          <ul className="pagination flex">
            <li key={"next"} className="page-item">
              <button
                className="page-link px-3 py-1 my-2 mx-1 border rounded btn btn-sm"
                onClick={handleOnNextClick}
                disabled={searching}
              >
                Show more...
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <Footer />
    </div>
  );
};

export default Home;