import React, { useState } from "react";
import { Login } from "../libs/api";
import { Link, useNavigate } from "react-router-dom";

const LoginForm = () => {
  const [errormsg, setErrormsg] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setErrormsg("");

    const email = e.target.elements.email.value.trim();
    const password = e.target.elements.password.value.trim();

    try {
      const response = await Login(email, password);

      if (response) {
        console.log("Login successful:", response);
        navigate("/");
        window.location.reload(); 
      }
    } catch (error) {
      console.error("Failed to connect:", error.message);
      setErrormsg("Invalid email or password.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="card-body gap-4 flex items-center">
        <h2 className="card-title justify-center">Welcome Back!</h2>
        <label className="input input-bordered flex items-center gap-2">
          <input
            name="email"
            type="email"
            className="grow"
            placeholder="Email"
            required
          />
        </label>
        <label className="input input-bordered flex items-center gap-2">
          <input
            name="password"
            type="password"
            className="grow"
            placeholder="Password"
            required
          />
        </label>
        {errormsg && (
          <div className="text-red-500 text-center text-sm mt-2">
            {errormsg}
          </div> )}
        <div className="card-actions justify-center">
          <button className="btn btn-primary w-24" disabled={loading}>
            {loading ? (
              <span className="loading loading-spinner text-primary"></span>
            ) : (
              "Login"
            )}
          </button>
        </div>
        <div className="text-center flex content-end">
          <p>Don't have an account?</p>
          <Link to="/register" className="font-semibold ml-1 text-[#B16332]">
            Register
          </Link>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
