import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteUser, getUser } from "../libs/api";
import { useAuth } from "../context/AuthContext";
import validator from "validator";
import PasswordChecklist from "react-password-checklist"; 
import axiosInstance from "../libs/axiosInstanc"; 
import Navbar from "../components/Navbar";

const EditProfile = () => {
  const { setIsLoggedIn } = useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    phoneNumber: "",
    tenantName: "",
    tenantSurname: "",
    accountType: "",
    accountEmail: "",
    tenantType: "",
  });

  const [originalData, setOriginalData] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});

  const [tempName, setTempName] = useState({ tenantName: "", tenantSurname: "" });
  const [tempPhone, setTempPhone] = useState("");

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [showPasswordChecklist, setShowPasswordChecklist] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  useEffect(() => {
    fetchLoggedInUser();
  }, []);

  const fetchLoggedInUser = async () => {
    try {
      const email = getEmailFromCookie();
      const data = await getUser(email);
      setFormData({
        tenantName: data.TenantName,
        tenantSurname: data.TenantSurname,
        phoneNumber: data.TenantTelephone,
        accountType: data.AccountType,
        accountEmail: data.AccountEmail,
        tenantType: data.TenantType,
      });
      setOriginalData({
        tenantName: data.TenantName,
        tenantSurname: data.TenantSurname,
        phoneNumber: data.TenantTelephone, 
        accountType: data.AccountType,
        accountEmail: data.AccountEmail,
        tenantType: data.TenantType,
      });
    } catch (error) {
      console.error("error fetch user data", error);
    }
  };

  const getEmailFromCookie = () => {
    const emailCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("AccountEmail="));
    if (!emailCookie) {
      throw new Error("AccountEmail cookie not found");
    }
    const email = emailCookie.split("=")[1];
    return email;
  };

  const handleDeleteUser = async () => {
    try {
      const email = getEmailFromCookie();
      await deleteUser(email, navigate);
      setIsLoggedIn(false);
    } catch (error) {
      alert(`Failed to delete account: ${error.message}`);
      console.error("Failed to delete user", error);
    }
  };

  const validateNameForm = (name, surname) => {
    const errors = {};
    if (validator.isEmpty(name)) {
      errors.tenantName = "First name is required.";
    }
    if (validator.isEmpty(surname)) {
      errors.tenantSurname = "Last name is required.";
    }
    return errors;
  };

  const validatePhoneForm = (phone) => {
    const errors = {};
    if (!validator.isMobilePhone(phone)) {
      errors.phoneNumber = "Invalid phone number.";
    }
    return errors;
  };

  const handleApplyName = async () => {
    const errors = validateNameForm(tempName.tenantName, tempName.tenantSurname);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setValidationErrors({});
    const requestBody = {};
    if (tempName.tenantName !== originalData.tenantName && tempName.tenantName.trim() !== "") {
      requestBody.TenantName = tempName.tenantName;
    }
    if (tempName.tenantSurname !== originalData.tenantSurname && tempName.tenantSurname.trim() !== "") {
      requestBody.TenantSurname = tempName.tenantSurname;
    }

    if (Object.keys(requestBody).length === 0) {
      document.getElementById('name_modal').close();
      return;
    }

    try {
      const response = await axiosInstance.put(`/account`, requestBody);
      if (response.status === 200) {
        console.log("Successfully updated user");
        setFormData((prev) => ({
          ...prev,
          tenantName: requestBody.TenantName ? requestBody.TenantName : prev.tenantName,
          tenantSurname: requestBody.TenantSurname ? requestBody.TenantSurname : prev.tenantSurname,
        }));
        setOriginalData((prev) => ({
          ...prev,
          tenantName: requestBody.TenantName ? requestBody.TenantName : prev.tenantName,
          tenantSurname: requestBody.TenantSurname ? requestBody.TenantSurname : prev.tenantSurname,
        }));
        document.getElementById('name_modal').close();
        window.location.reload();
      } else {
        throw new Error("Failed to update user");
      }
    } catch (error) {
      alert(`Update failed: ${error.message}`);
      console.error("Failed to update user", error);
    }
  };

  const handleApplyPhone = async () => {
    const errors = validatePhoneForm(tempPhone);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setValidationErrors({});
    const requestBody = {};
    if (tempPhone !== originalData.phoneNumber && tempPhone.trim() !== "") {
      requestBody.TenantTelephone = tempPhone;
    }

    if (Object.keys(requestBody).length === 0) {
      document.getElementById('phone_modal').close();
      return;
    }

    try {
      const response = await axiosInstance.put(`/account`, requestBody);
      if (response.status === 200) {
        console.log("Successfully updated user");
        setFormData((prev) => ({
          ...prev,
          phoneNumber: requestBody.TenantTelephone ? requestBody.TenantTelephone : prev.phoneNumber,
        }));
        setOriginalData((prev) => ({
          ...prev,
          phoneNumber: requestBody.TenantTelephone ? requestBody.TenantTelephone : prev.phoneNumber,
        }));
        document.getElementById('phone_modal').close();
        window.location.reload();
      } else {
        throw new Error("Failed to update user");
      }
    } catch (error) {
      alert(`Update failed: ${error.message}`);
      console.error("Failed to update user", error);
    }
  };

  const handleApplyPassword = async () => {
    if (!isPasswordValid || password.length === 0) {
      return;
    }

    const requestBody = {
      AccountPassword: password
    };

    try {
      const response = await axiosInstance.put(`/account`, requestBody);
      if (response.status === 200) {
        console.log("Successfully updated password");
        document.getElementById('password_modal').close();
        window.location.reload();
      } else {
        throw new Error("Failed to update password");
      }
    } catch (error) {
      alert(`Update failed: ${error.message}`);
      console.error("Failed to update password", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="w-full bg-gray-100 min-h-screen py-8">
        <div className="max-w-4xl mx-auto bg-white p-8 rounded shadow">
          <h1 className="text-3xl font-bold mb-6">Profile</h1>

          {/* Personal Info Section */}
          <div className="border-b pb-6 mb-6">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-xl font-bold mb-2">Personal Info</h2>
                <p className="text-sm text-gray-500 mb-4">
                  Your first and last given names. Updates are reflected across all experiences.
                </p>
              </div>
              <button
                className="text-blue-600 hover:underline"
                onClick={() => navigate("/searchhistory")}
              >
                View Search History
              </button>
            </div>
            
            {/* Name Row */}
            <div className="flex justify-between items-center py-2">
              <div>
                <p className="font-medium">Name</p>
                <p className="text-gray-700">{formData.tenantName} {formData.tenantSurname}</p>
              </div>
              <button
                className="text-blue-600 hover:underline"
                onClick={() => {
                  setTempName({ tenantName: "", tenantSurname: "" });
                  setValidationErrors({});
                  document.getElementById('name_modal').showModal();
                }}
              >
                Edit
              </button>
            </div>

            {/* Telephone Row */}
            <div className="flex justify-between items-center py-2">
              <div>
                <p className="font-medium">Phone Number</p>
                <p className="text-gray-700">{formData.phoneNumber}</p>
              </div>
              <button
                className="text-blue-600 hover:underline"
                onClick={() => {
                  setTempPhone("");
                  setValidationErrors({});
                  document.getElementById('phone_modal').showModal();
                }}
              >
                Edit
              </button>
            </div>

            {/* Account Type Row  */}
            <div className="flex justify-between items-center py-2">
              <div>
                <p className="font-medium">Account Type</p>
                <p className="text-gray-700">{formData.accountType}</p>
              </div>
              <div></div>
            </div>

            {/* Tenant Type Row  */}
            <div className="flex justify-between items-center py-2">
              <div>
                <p className="font-medium">Tenant Type</p>
                <p className="text-gray-700">{formData.tenantType}</p>
              </div>
              <div></div>
            </div>
          </div>

          {/* Sign in & Security Section */}
          <div className="border-b pb-6 mb-6">
            <h2 className="text-xl font-bold mb-2">Sign in & Security</h2>

            {/* Email Row */}
            <div className="flex justify-between items-center py-2">
              <div>
                <p className="font-medium">Email</p>
                <p className="text-gray-700">{formData.accountEmail}</p>
              </div>
              <div></div>
            </div>

            {/* Password Row */}
            <div className="flex justify-between items-center py-2">
              <div>
                <p className="font-medium">Password</p>
                <p className="text-gray-700">********</p>
              </div>
              <button
                className="text-blue-600 hover:underline"
                onClick={() => {
                  setPassword("");
                  setPasswordAgain("");
                  setShowPasswordChecklist(false);
                  setIsPasswordValid(false);
                  document.getElementById('password_modal').showModal();
                }}
              >
                Edit
              </button>
            </div>
          </div>

          {/* Manage Account Section */}
          <div className="pb-6">
            <h2 className="text-xl font-bold mb-2">Manage Account</h2>

            <div className="flex justify-between items-center py-2">
              <div>
                <p className="font-medium">Deactivate my account</p>
                <p className="text-gray-500 text-sm">This will shut down your account, and remove it completely from the system.</p>
              </div>
              <button
                className="text-blue-600 hover:underline"
                onClick={() => document.getElementById("delete_profile").showModal()}
              >
                Delete account
              </button>
            </div>
          </div>


          {/* Delete Account Modal */}
          <dialog id="delete_profile" className="modal">
            <div className="modal-box">
              <h3 className="font-bold text-lg">Delete Account?</h3>
              <p className="py-4">
                Are you sure you want to delete your profile?
              </p>
              <div className="modal-action">
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    document.getElementById("delete_profile").close()
                  }
                >
                  Cancel
                </button>
                <button className="btn" onClick={handleDeleteUser}>
                  Confirm
                </button>
              </div>
            </div>
            <form method="dialog" className="modal-backdrop">
              <button>close</button>
            </form>
          </dialog>

          {/* Edit Name Modal */}
          <dialog id="name_modal" className="modal">
            <div className="modal-box">
              <h3 className="font-bold text-lg mb-2">Edit name</h3>
              <div className="flex gap-4">
                <div className="flex flex-col w-1/2">
                  <label className="text-sm font-medium mb-1">First name</label>
                  <input
                    type="text"
                    className="input input-bordered"
                    value={tempName.tenantName}
                    placeholder="First name"
                    onChange={(e) => setTempName((prev) => ({ ...prev, tenantName: e.target.value }))}
                  />
                  {validationErrors.tenantName && (
                    <span className="text-red-500 text-xs">{validationErrors.tenantName}</span>
                  )}
                </div>
                <div className="flex flex-col w-1/2">
                  <label className="text-sm font-medium mb-1">Last name</label>
                  <input
                    type="text"
                    className="input input-bordered"
                    value={tempName.tenantSurname}
                    placeholder="Last name"
                    onChange={(e) => setTempName((prev) => ({ ...prev, tenantSurname: e.target.value }))}
                  />
                  {validationErrors.tenantSurname && (
                    <span className="text-red-500 text-xs">{validationErrors.tenantSurname}</span>
                  )}
                </div>
              </div>
              <div className="modal-action">
                <button className="btn btn-ghost" onClick={() => {document.getElementById('name_modal').close(); setValidationErrors({});}}>Cancel</button>
                <button className="btn btn-primary" onClick={handleApplyName}>Apply</button>
              </div>
            </div>
            <form method="dialog" className="modal-backdrop">
              <button>close</button>
            </form>
          </dialog>

          {/* Edit Phone Modal */}
          <dialog id="phone_modal" className="modal">
            <div className="modal-box">
              <h3 className="font-bold text-lg mb-2">Edit phone number</h3>
              <div className="flex flex-col mb-4">
                <label className="text-sm font-medium mb-1">Phone number</label>
                <input
                  type="text"
                  className="input input-bordered"
                  value={tempPhone}
                  placeholder="123-456-7890"
                  onChange={(e) => setTempPhone(e.target.value)}
                />
                {validationErrors.phoneNumber && (
                  <span className="text-red-500 text-xs">{validationErrors.phoneNumber}</span>
                )}
              </div>
              <div className="modal-action">
                <button className="btn btn-ghost" onClick={() => {document.getElementById('phone_modal').close(); setValidationErrors({});}}>Cancel</button>
                <button className="btn btn-primary" onClick={handleApplyPhone}>Apply</button>
              </div>
            </div>
            <form method="dialog" className="modal-backdrop">
              <button>close</button>
            </form>
          </dialog>

          {/* Edit Password Modal */}
          <dialog id="password_modal" className="modal">
            <div className="modal-box">
              <h3 className="font-bold text-lg mb-2">Change password</h3>
              <div className="flex flex-col mb-4">
                <label className="form-control mb-2">
                  <span className="label-text text-xs lg:text-base">Password</span>
                  <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setShowPasswordChecklist(true);
                    }}
                    className="input input-bordered"
                    placeholder="********"
                    onKeyUp={() => setShowPasswordChecklist(true)}
                  />
                </label>
                <label className="form-control mb-2">
                  <span className="label-text text-xs lg:text-base">
                    Confirm Password
                  </span>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={passwordAgain}
                    onChange={(e) => {
                      setPasswordAgain(e.target.value);
                    }}
                    className="input input-bordered"
                    placeholder="********"
                  />
                </label>
                {showPasswordChecklist && (
                  <PasswordChecklist
                    className="text-sm"
                    invalidTextColor='red'
                    validTextColor='green'
                    iconSize={12}
                    rules={[
                      "minLength",
                      "specialChar",
                      "number",
                      "capital",
                      "match",
                      {
                        rule: "specialChar",
                        regex: /[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/,
                        message: "Password must contain a special character"
                      }
                    ]}
                    minLength={12}
                    value={password}
                    valueAgain={passwordAgain}
                    onChange={(isValid) => setIsPasswordValid(isValid)}
                  />
                )}
              </div>
              <div className="modal-action">
                <button className="btn btn-ghost" onClick={() => document.getElementById('password_modal').close()}>Cancel</button>
                <button className="btn btn-primary" onClick={handleApplyPassword} disabled={!isPasswordValid}>Apply</button>
              </div>
            </div>
            <form method="dialog" className="modal-backdrop">
              <button>close</button>
            </form>
          </dialog>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
