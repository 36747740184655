import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import LoginForm from "./LoginForm";
import { Logout } from "../libs/api";
import logo from "../assets/logo.svg";
import { useAuth } from "../context/AuthContext";

const Navbar = () => {
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const Authorization = document.cookie.split('; ').find(row => row.startsWith('Authorization='));
    setIsLoggedIn(!!Authorization);
  }, [setIsLoggedIn]);

  const handleLogout = async () => {
    const emailCookie = document.cookie.split('; ').find(row => row.startsWith('AccountEmail='));
    if (emailCookie) {
      try {
        await Logout(navigate);
        setIsLoggedIn(false);
      } catch (error) {
        console.error("Logout failed:", error);
        alert("Failed to log out. Please try again.");
      }
    } else {
      console.warn("No AccountEmail cookie found; user might already be logged out.");
    }
  };
  

  return (
    <div className="navbar bg-base-100 shadow-sm flex-wrap px-8">
      <div className="navbar-start">
        <Link className="text-xl font-bold " to="/">
          <img src={logo} style={{width: '150px', height: '150px'}} alt="Husom"  className=" logo-image"/>
        </Link>
      </div>
      <div className="navbar-center">
     
      </div>
      <div className="navbar-end gap-2">
        {isLoggedIn ? (
          <>
            <Link className="btn btn-sm" to="/profile">Profile</Link>
            <button className="btn btn-sm" onClick={handleLogout}>Logout</button>
          </>
        ) : (
          <>
            <button
              className="btn btn-sm"
              onClick={() => document.getElementById("login").showModal()}
            >
              Login
            </button>
          </>
        )}
        <dialog id="login" className="modal">
          <div className="modal-box">
            <LoginForm />
          </div>
          <form method="dialog" className="modal-backdrop">
            <button>Close</button>
          </form>
        </dialog>
      </div>
    </div>
  );
};

export default Navbar;