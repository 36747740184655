import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSearchHistory, getSearchDetail } from "../libs/api";
import Navbar from "../components/Navbar";

const SearchHistory = () => {
  const navigate = useNavigate();
  const [searchHistory, setSearchHistory] = useState([]);
  const [loading, setLoading] = useState(true); 
  const pageLimit=10;
  useEffect(() => {
    const fetchSearchHistory = async () => {
      try {
        const response = await getSearchHistory();
        let data = response["SearchIds"];   
        data = data.sort((a, b) => b.SearchId - a.SearchId);     
        let result = [];
        for (let i =0; i<pageLimit; i++) {
          const searchHistory = data[i];
          const id = searchHistory.SearchId;
          const detail = await getSearchDetail(id);
          result = [...result, mapDataToUI(detail.Search)];
        }
        setSearchHistory(result);
      } catch (error) {
        console.error("Failed to fetch search history", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchSearchHistory();
  }, []);

  const mapDataToUI = (data) => {
    let result = {
      SearchId: {
        name: "Search Id",
        value: data.SearchId,
      },
      PriceMin: {
        name: "Price Min",
        value: data.MinPrice,
      },
      PriceMax: {
        name: "Price Max",
        value: data.MaxPrice,
      },
      BedroomMin: {
        name: "Bedroom Min",
        value: data.MinBedrooms,
      },
      BedroomMax: {
        name: "Bedroom Max",
        value: data.MaxBedrooms,
      },
      PropertyType: {
        name: "Property Type",
        value: data.PropertyType,
      },
      Availability: {
        name: "Availability",
        value: data.Availability,
      },
      PostCode: {
        name:"Zip Code",
        value: data.PostCode,
      },
      Radius: {
        name: "Radius",
        value: data.Radius,
      },
      LastRun: {
        name: "Searched At",
        value: data.LastRun,
      }
    }

    const filteredResult = Object.fromEntries(
      Object.entries(result).filter(([key, value]) => !!value?.value)
    );
    console.log(filteredResult)
    return filteredResult;
  }
  const mapToUrlQueryParams = (item) => {
    const params = {
      priceMin: item.PriceMin?.value,
      priceMax: item.PriceMax?.value,
      zipcode: item.PostCode?.value,
      radius: item.Radius?.value,
      bedroomMin: item.BedroomMin?.value,
      bedroomMax: item.BedroomMax?.value,
      availability: item.Availability?.value,
      propertyType: item.PropertyType?.value
    }

   const filteredParams = Object.entries(params).filter(([key, value]) => !!value);
    return filteredParams;
  }

  const handleItemClick = (item) => {
    const params = mapToUrlQueryParams(item);
    const queryParams = new URLSearchParams(params).toString();
    navigate(`/?${queryParams}`);
  };

  return (
    <div>
      <Navbar />
      <div className="w-full bg-gray-100 min-h-screen py-8">
        <div className="max-w-4xl mx-auto bg-white p-8 rounded shadow">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold py-2">Search History</h1>
            <button
              className="text-blue-600 hover:underline flex items-center"
              onClick={() => navigate("/profile")}
            >
              <span className="mr-2 text-lg">&lt;</span> Back to Profile
            </button>
          </div>
          <div className="divider"></div>
          <div className="space-y-4 mt-4">
            {loading ? (
              <div className="flex justify-center items-center h-40">
                <span className="loading loading-spinner text-primary text-4xl"></span>
              </div>
            ) : searchHistory && searchHistory.length > 0 ? (
              searchHistory.map((item, index) => (
                <div 
                  key={index} 
                  className="p-4 border rounded shadow hover:bg-gray-50 cursor-pointer"
                  onClick={() => handleItemClick(item)}
                >
                  {Object.entries(item).map(([key, value]) => (
                    <div key={key} className="flex justify-between py-1">
                      <span className="font-semibold">{value.name}:</span>
                      <span>{value.value}</span>
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <p>No search history found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchHistory;
