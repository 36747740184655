import axios from "axios";
import {createBrowserHistory} from "history";
const API_URL =
  window.location.hostname === "www.husomapp.com"
    ? "https://www.husomapp.com"
   : "https://test.husomapp.com";
  // :"https://cors-anywhere.herokuapp.com/https://test.husomapp.com";

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = document.cookie
      .split("; ")
      .find((row) => row.startsWith("Authorization="))
      ?.split("=")[1];
    const email = document.cookie
      .split("; ")
      .find((row) => row.startsWith("AccountEmail="))
      ?.split("=")[1];

    if (token) {
      config.headers["Authorization"] = token;
      config.headers["Account"] = email;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data?.Header?.Code === 401) {
      logout();
      redirectToHome();
      return Promise.reject(response);
    }

    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      logout();
      redirectToHome();
    }
    return Promise.reject(error);
  }
);

const logout =() => {
    
document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
document.cookie = "AccountEmail=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

}

const redirectToHome =() =>{
    const history = createBrowserHistory()
    history.push('/')
}


export default axiosInstance;
