import React from "react";

const TermsOfUse = () => {
  return (
    <div className="bg-gray-50 p-6 rounded-lg shadow-lg max-w-4xl mx-auto my-8">
      <h1 className="text-3xl font-bold text-center text-brown-600 mb-4">Terms of Use</h1>
      <p className="text-gray-700 mb-4">
        <strong>Effective Date:</strong> December 11, 2024
      </p>
      <p className="text-gray-700 mb-4">
        Welcome to <strong>Husom</strong>! These Terms of Use (“Terms”) govern your access to and use of our property rental website and related services (“Services”). By using our Services, you agree to comply with these Terms. If you do not agree, you must refrain from using our Services.
      </p>
      <h2 className="text-xl font-semibold text-brown-600 mb-2">1. Eligibility</h2>
      <p className="text-gray-700 mb-4">
        - By using Husom, you confirm that you are at least <strong>18 years old</strong>.
        <br />
        - If you are under 18, you are prohibited from using our Services.
      </p>
      <h2 className="text-xl font-semibold text-brown-600 mb-2">2. Account Registration</h2>
      <p className="text-gray-700 mb-4">
        - To use certain features of Husom, you must create an account and provide accurate and up-to-date information.
        <br />
        - You are responsible for maintaining the confidentiality of your account credentials and all activities under your account.
      </p>
      <h2 className="text-xl font-semibold text-brown-600 mb-2">3. Use of Services</h2>
      <p className="text-gray-700 mb-4">
        - You agree to use Husom only for lawful purposes and in compliance with all applicable laws.
        <br />
        - You will not: 
        <ul className="list-disc pl-6">
          <li>Use Husom for fraudulent purposes.</li>
          <li>Violate the rights of others, including property owners and renters.</li>
          <li>Attempt to disrupt or compromise the security of our platform.</li>
        </ul>
      </p>
      <h2 className="text-xl font-semibold text-brown-600 mb-2">4. Property Listings</h2>
      <p className="text-gray-700 mb-4">
        All property listings must be accurate and lawful. Husom reserves the right to remove any listings that violate our policies.
      </p>
      <h2 className="text-xl font-semibold text-brown-600 mb-2">5. Privacy</h2>
      <p className="text-gray-700 mb-4">
        Your use of Husom is subject to our Privacy Policy, which explains how we collect, use, and protect your data.
      </p>
      <h2 className="text-xl font-semibold text-brown-600 mb-2">6. Limitation of Liability</h2>
      <p className="text-gray-700 mb-4">
        Husom provides a platform for property rental but does not guarantee the accuracy of listings or the quality of rental arrangements. We are not liable for any disputes, damages, or losses arising from your use of our Services.
      </p>
      <h2 className="text-xl font-semibold text-brown-600 mb-2">7. Termination</h2>
      <p className="text-gray-700 mb-4">
        Husom reserves the right to terminate or suspend your account for violations of these Terms.
      </p>
      <h2 className="text-xl font-semibold text-brown-600 mb-2">8. Changes to Terms</h2>
      <p className="text-gray-700 mb-4">
        Husom may update these Terms at any time. Changes will be effective upon posting to our website.
      </p>
      <h2 className="text-xl font-semibold text-brown-600 mb-2">9. Governing Law</h2>
      <p className="text-gray-700 mb-4">
        These Terms are governed by the the laws of the United States.
      </p>
      <h2 className="text-xl font-semibold text-brown-600 mb-2">10. Contact Us</h2>
      <p className="text-gray-700 mb-4">
        For questions about these Terms, please contact us at:
        <br />
        <strong>Email:</strong> [ support@husom.com]
        <br />
        <strong>Phone:</strong> [+1-123-123-1234]
      </p>
    </div>
  );
};

export default TermsOfUse;
