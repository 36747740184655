import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { BASE_IMAGE_URL } from '../constants/url'; 
import logo from '../assets/logo.svg'; 
import { useEffect } from "react";
import { getPropertyDetail } from "../libs/api.js";

const PropertyDetail = () => {
  const { id } = useParams();
  const [propertyDetail, setPropertyDetail] = React.useState({});

  useEffect(() => {
    fetchProperty();
  }, []);

  const fetchProperty = async () => {
    const detail = await getPropertyDetail(id);
    setPropertyDetail(detail.Property);
  };

  const getInitials = (name, surname) => {
    const nameInitial = name ? name.charAt(0).toUpperCase() : '';
    const surnameInitial = surname ? surname.charAt(0).toUpperCase() : '';
    return `${nameInitial}${surnameInitial}`;
  };

  const getImageUrl = (filename) => {
    return filename ? `${BASE_IMAGE_URL}/${propertyDetail.PropertyId}/${filename}` : logo;
  };

  const price = propertyDetail.Price ? parseInt(propertyDetail.Price, 10) : '';
  const images = propertyDetail?.Images || [];

  return (
    <div className="w-screen min-h-screen bg-gray-100 flex justify-center py-8 px-4">
      <div className="max-w-6xl w-full bg-white shadow-lg rounded-lg overflow-hidden">
        
        {/* Top Bar: Landlord Info and Back Link */}
        <div className="p-4 border-b border-gray-200 flex items-center justify-between">
          <div className="flex items-center">
            <div className="bg-white rounded-full p-2 shadow-md flex items-center justify-center w-12 h-12">
              <span className="text-xl font-bold">
                {getInitials(propertyDetail.LandlordName)}
              </span>
            </div>
            <span className="ml-4 text-xl font-bold">{propertyDetail.LandlordName}</span>
          </div>

          <div>
            <Link to="/" className="text-gray-600 flex items-center hover:underline">
              <span className="mr-2 text-lg">&lt;</span> 
              <span>Back to search</span>
            </Link>
          </div>
        </div>

        {/* Images Section */}
        <div className="p-4 border-b border-gray-200">
          {images.length > 0 ? (
            <div className="grid gap-2 grid-cols-1 md:grid-cols-2">
              {images.map((imgObj, index) => (
                <div key={index} className="relative w-full h-96">
                  <img
                    src={getImageUrl(imgObj.Image.Filename)}
                    alt={imgObj.Image.ImageDescription || 'Property image'}
                    className="w-full h-full object-cover rounded"
                  />
                  {imgObj.Image.ImageDescription && (
                    <figcaption className="absolute bottom-0 bg-black bg-opacity-50 text-white p-2 text-sm">
                      {imgObj.Image.ImageDescription}
                    </figcaption>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full h-96 bg-gray-300 flex items-center justify-center text-gray-500 italic">
              No images available
            </div>
          )}
        </div>

        {/* Property Details */}
        <div className="p-6">
          <div className="flex flex-col md:flex-row md:items-end md:justify-between">
            <div className="mb-4 md:mb-0">
              {/* Rent Price and Postcode */}
              {price && (
                <h1 className="text-3xl font-bold">${price}/mo</h1>
              )}
              {propertyDetail.PostCode && (
                <p className="text-gray-500">Postcode: {propertyDetail.PostCode}</p>
              )}
            </div>

            {/* Bedrooms, Lease (no bathrooms) */}
            <div className="flex gap-8">
              {/* Bedrooms */}
              {propertyDetail.Bedrooms && (
                <div className="text-center">
                  <p className="text-2xl font-bold">{propertyDetail.Bedrooms}</p>
                  <p className="text-sm text-gray-600">bedrooms</p>
                </div>
              )}

              {/* Lease */}
              {(propertyDetail.MinRental && propertyDetail.MaxRental) && (
                <div className="text-center">
                  <p className="text-2xl font-bold">
                    {propertyDetail.MinRental} - {propertyDetail.MaxRental} <span className="text-lg">/mo</span>
                  </p>
                  <p className="text-sm text-gray-600">lease</p>
                </div>
              )}
            </div>
          </div>

          {/* Gray Boxes*/}
          <div className="flex flex-wrap gap-4 mt-6">
            {/* Landlord Box */}
            {(propertyDetail.LandlordContactName || propertyDetail.LandlordContactSurname) && (
              <div className="bg-gray-200 p-4 rounded flex-1 sm:flex-none min-h-[80px]">
                <p className="text-xs text-gray-500 mb-1">Landlord</p>
                <p className="font-bold text-md">
                  {propertyDetail.LandlordContactName} {propertyDetail.LandlordContactSurname}
                </p>
              </div>
            )}

            {/* Contact Box */}
            {propertyDetail.LandlordTelephone && (
              <div className="bg-gray-200 p-4 rounded flex-1 sm:flex-none min-h-[80px]">
                <p className="text-xs text-gray-500 mb-1">Contact</p>
                <p className="font-bold text-md">{propertyDetail.LandlordTelephone}</p>
              </div>
            )}

            {/* Availability Box */}
            {propertyDetail.Availability && (
              <div className="bg-gray-200 p-4 rounded flex-1 sm:flex-none min-h-[80px]">
                <p className="text-xs text-gray-500 mb-1">Availability</p>
                <p className="font-bold text-md">{propertyDetail.Availability}</p>
              </div>
            )}

            {/* Property Type Box */}
            {propertyDetail.PropertyType && (
              <div className="bg-gray-200 p-4 rounded flex-1 sm:flex-none min-h-[80px]">
                <p className="text-xs text-gray-500 mb-1">Property Type</p>
                <p className="font-bold text-md">{propertyDetail.PropertyType}</p>
              </div>
            )}

            {/* Deposit Box */}
            {price && (
              <div className="bg-gray-200 p-4 rounded flex-1 sm:flex-none min-h-[80px]">
                <p className="text-xs text-gray-500 mb-1">Deposit</p>
                <p className="font-bold text-md">${price}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetail;
