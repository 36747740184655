import axiosInstance from "./axiosInstanc";

const setCookie = (name, value, days) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${value}; expires=${expires}; path=/`;
};

export const Login = async (email, password) => {
  try{
    const response =await axiosInstance.post(`/account/login`, {
      AccountEmail: email,
      AccountPassword: password,
    });

    // Check if the response is valid
    if (response.status === 200 && response.data.Body) {
      const { Authorization, AccountEmail } = response.data.Body;

      if (!Authorization || !AccountEmail) {
        throw new Error("Invalid response: Missing Authorization or AccountEmail.");
      }

      // Set Authorization and AccountEmail cookies
      setCookie("Authorization", Authorization, 7);
      setCookie("AccountEmail", AccountEmail, 7);

      console.log("Authorization cookie set:", Authorization);
      console.log("AccountEmail cookie set:", AccountEmail);

      return { Authorization, AccountEmail };
    } else {
      throw new Error("Invalid login response.");
    }
  } catch (error) {
    console.error("Error during login:", error.message);
    throw error;
  }
};


export const Logout = async (navigate) => {
  try {
    const token = document.cookie
      .split("; ")
      .find((row) => row.startsWith("Authorization="))
      ?.split("=")[1];
    const email = document.cookie
      .split("; ")
      .find((row) => row.startsWith("AccountEmail="))
      ?.split("=")[1];

    if (!token || !email) {
      throw new Error("Missing Authorization token or AccountEmail in cookies.");
    }

    // Send POST request with headers
    const response = await axiosInstance.post(
      `/account/logout`,
      {}, // Empty body
     
    );

    if (response.status === 200) {
      // Clear cookies
      document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "AccountEmail=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      console.log("Successfully logged out");
      navigate("/"); // Redirect to the home page
    } else {
      throw new Error("Failed to logout from the account.");
    }
  } catch (error) {
    console.error("Logout error:", error.message);
    alert("Failed to logout. Please try again.");
    throw error;
  }
};


export const registerUser = async (fname,lname, email, password, telephone, navigate) => {
  const requestBody = {
    AccountEmail: email,
    AccountPassword: password,
    TenantName: fname,
    TenantSurname: lname,
    TenantType: "student",
    TenantTelephone: telephone,
  };

  try {
    const response = await axiosInstance.post(`/account/`, requestBody);

    if (response.status === 200) {
      navigate("/");
    } else {
      throw new Error("Registration failed");
    }
  } catch (error) {
    console.error("Error during registration:", error);
    alert("Registration failed. Please try again.");
    throw error; // 
  }
};

export const updateUser = async (email, phone, password, tenantName, tenantSurname, navigate) => {
  const requestBody = {
    TenantTelephone: phone,
    TenantName: tenantName,
    TenantSurname: tenantSurname,
    AccountPassword: password,};

  const response = await axiosInstance.put(`/account`, requestBody, {
   
  });
  if (response.status === 200) {
    console.log("Successfully updated user");
    navigate("/");
  } else {
    throw new Error("Failed to update user");
  }
};

export const deleteUser = async (email, navigate) => {
  const token = document.cookie.split('; ').find(row => row.startsWith('Authorization=')).split('=')[1];

  const response = await axiosInstance.delete(`/account`, {
    headers: {
      Authorization: token,
      Account: email
    }
  });

  if (response.status === 200) {
    // Clear cookies
    document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "AccountEmail=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    console.log("Successfully deleted account");
    navigate("/");
  } else {
    throw new Error("Failed to delete account");
  }
};
export const getUser = async (email) => {
  const token = document.cookie.split('; ').find(row => row.startsWith('Authorization=')).split('=')[1];

  const response = await axiosInstance.get(`/account`, {
    headers: {
      Authorization: token,
      Account: email
    }
  });
  if (response.status === 200) {
    console.log("Successfully get user", response.data);
    return response.data['Body'];
  } else {
    throw new Error("Failed to get user");
  }
};

export const getProperty = async (start, limit) => {
   let query = "?";
  if (!!start){
    query = query+"start="+start;

  }
  if (!!limit){
    query = query+"&limit="+limit;
  }
  const response = await axiosInstance.get(`/property${query}`, {
 
  });
  if (response.status === 200) {
    console.log("Successfully get property", response.data);
    return response.data['Body'];
  } else {
    throw new Error("Failed to get property");
  }
};

export const getPropertyDetail = async (propertyID, email) => {
  
  const response = await axiosInstance.get(`/property/${propertyID}`, {
   
  });
  if (response.status === 200) {
    console.log("Successfully get property", response.data);
    return response.data['Body'];
  } else {
    throw new Error("Failed to get property");
  }
};


export const apiFilterProperty = async (priceMin,priceMax,bedroomMin,bedroomMax,type,availability,start,limit, zipcode, radius) => {
  let query = "";
  if (!!priceMin){
    query = query+"price-min="+priceMin;
  }
  if (!!priceMax){
    query = (!!query? query +"&" :"")+"price-max="+priceMax;
  }
  if (!!bedroomMin){
      query = (!!query? query +"&" :"")+"bedroom-min="+bedroomMin;
  }
  if (!!bedroomMax){
      query = (!!query? query +"&" :"")+"bedroom-max="+bedroomMax;
  }
  if (!!type){
    query = (!!query? query +"&" :"")+"type="+type;
   }
  if (!!availability){
   query = (!!query? query +"&" :"")+"availability="+availability;
  } 
  if (!!start){
    query = (!!query? query +"&" :"")+"start="+start;
  }
  if (!!limit){
   query = (!!query? query +"&" :"")+"limit="+limit;
  }
  if (!!zipcode){
    query = (!!query? query +"&" :"")+"postcode="+zipcode;
  }
  if (!!zipcode && !!radius){
    query = (!!query? query +"&" :"")+"radius="+radius;
  }
  if(!!query){
    query ="?"+query
  }
  const response = await axiosInstance.get(`/property/search${query}`, {
  
  });
  if (response.status === 200) {
    console.log("Property search Successfully ", response.data);
    return response.data['Body'];
  } else {
    throw new Error("Failed to search property");
  }
};

export const getZipCode = async (prefix) => {

  const endpoint = `/zip?prefix=${prefix}`;
  const response = await axiosInstance.get(endpoint);
  if (response.status === 200) {
    let data = response.data;
    console.log(data);
    if (!isObject(data) && data.includes("'")) {
      data = data.replace(/'/g, '"');
      console.log("Replaced single quotes with double quotes", data);
      return JSON.parse(data)['Body'];

    }
    console.log("Successfully get zipcode", data);
    return data['Body'];
  } else {
    throw new Error("Failed to get zipcode");
  }
};

function isObject(value) {
  return value && typeof value === 'object' && !Array.isArray(value);
}
export const getSearchHistory = async () => {
  const response = await axiosInstance.get(`/search`, {
  
  });
  if (response.status === 200) {
    console.log("Successfully get search history", response.data);
    return response.data['Body'];
  } else {
    throw new Error("Failed to get search history");
  }
}
export const getSearchDetail = async (searchId) => {
  
  const response = await axiosInstance.get(`/search/${searchId}`, {
   
  });
  if (response.status === 200) {
    console.log("Successfully get property", response.data);
    return response.data['Body'];
  } else {
    throw new Error("Failed to get property");
  }
};
