import React from 'react';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Register from './pages/Register';
import Home from './pages/Home.js';
import { AuthProvider } from './context/AuthContext';
import './App.css';
import PropertyDetail from './pages/PropertyDetail';
import EditProfile from './pages/EditProfile.js';
import SearchHistory from './pages/SearchHistory.js';


function App() {

  const ProtectedRoute = ({ element }) => {
    const isLoggedIn = document.cookie.split('; ').some(row => row.startsWith('Authorization='));

    if(!isLoggedIn){
      return <Navigate to='/'></Navigate>
    }
    return element;
  };

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<ProtectedRoute element={<EditProfile />} />} />
            <Route path='/searchhistory' element={<ProtectedRoute element={<SearchHistory />} />} />
            <Route path="/property-detail/:id" element={<PropertyDetail />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
