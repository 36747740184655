import React, { useState } from "react";
import validator from "validator";
import { registerUser } from "../libs/api";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import PolicyTermofUse from "../components/PolicyTermofUse";
import PasswordChecklist from "react-password-checklist";

const Register = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    password: "",
    confirmPassword: "",
  });

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [ischecked, setIsChecked] = useState(false);
  const [showPasswordChecklist, setShowPasswordChecklist] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName" || name === "lastName") {
      if (/^[a-zA-Z]*$/.test(value)) {
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const validateForm = () => {
    const errors = {};

    if (validator.isEmpty(formData.firstName)) {
      errors.firstName = "First name is required.";
    }
    if (validator.isEmpty(formData.lastName)) {
      errors.lastName = "Last name is required.";
    }
    if (!validator.isEmail(formData.email)) {
      errors.email = "Invalid email address.";
    }
    if (!validator.isMobilePhone(formData.telephone)) {
      errors.telephone = "Invalid phone number.";
    }
    if (!validator.isLength(formData.password, { min: 6 })) {
      errors.password = "Password must be at least 6 characters long.";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleOnRegister = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const { firstName, lastName, email, password, telephone } = formData;
      await registerUser(
        firstName,
        lastName,
        email,
        password,
        telephone,
        navigate
      );
    } catch (error) {
      alert("Registration failed. Please try again.");
    }
  };

  return (
    <div className="flex flex-col lg:flex-row w-screen h-screen items-center justify-items-center">
      <div className="lg:w-[500px] w-full items-center h-full flex flex-col justify-center content-center lg:px-36">
        <h1 className="text-4xl font-bold mr-36 mb-4">Register</h1>
        <div className="flex flex-col lg:w-[400px] w-[300px] md:w-[350px]">
          <div className="flex flex-row gap-4">
            <label className="form-control w-full max-w-xs">
              <span className="label-text text-xs lg:text-base">
                First Name
              </span>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                className="input input-bordered w-full max-w-xs"
              />
              <span className={`text-red-500 text-xs min-h-[20px] block`}>
                {validationErrors.firstName || "\u00A0"}
              </span>
            </label>
            <label className="form-control w-full max-w-xs">
              <span className="label-text text-xs lg:text-base">Last Name</span>
              <input
                
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                className="input input-bordered w-full max-w-xs"
              />
              <span className={`text-red-500 text-xs min-h-[20px] block`}>
                {validationErrors.lastName || "\u00A0"}
              </span>
            </label>
          </div>
          <label className="form-control">
            <span className="label-text text-xs lg:text-base">Email</span>
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="input input-bordered"
              placeholder="example@example.com"
            />
            <span className={`text-red-500 text-xs min-h-[20px] block`}>
              {validationErrors.email || "\u00A0"}
            </span>
          </label>
          <label className="form-control">
            <span className="label-text text-xs lg:text-base">Telephone</span>
            <input
              type="number"
              name="telephone"
              value={formData.telephone}
              onChange={handleInputChange}
              className="input input-bordered"
              placeholder="+1234567890"
            />
            <span className={`text-red-500 text-xs min-h-[20px] block`}>
              {validationErrors.telephone || "\u00A0"}
            </span>
          </label>
          <label className="form-control">
            <span className="label-text text-xs lg:text-base">Password</span>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={(e) => {
                handleInputChange(e);
                setPassword(e.target.value);
                setShowPasswordChecklist(true);
              }}
              className="input input-bordered"
              placeholder="********"
              onKeyUp={() => setShowPasswordChecklist(true)}
            />
          </label>
          <label className="form-control">
            <span className="label-text text-xs lg:text-base">
              Confirm Password
            </span>
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={(e) => {
                handleInputChange(e);
                setPasswordAgain(e.target.value);
              }}
              className="input input-bordered"
              placeholder="********"
            />
            {showPasswordChecklist && (
              <PasswordChecklist
                className="text-sm"
                invalidTextColor='red'
                validTextColor='green'
                iconSize={12}
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "match",
                  {
                    rule: "specialChar",
                    regex: /[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/,
                    message: "Password must contain a special character from the set: !\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~"
                  }
                ]}
                minLength={12}
                value={password}
                valueAgain={passwordAgain}
                onChange={(isValid) => setIsPasswordValid(isValid)}
              />
            )}
          </label>
          <ReCAPTCHA
            sitekey="6LcteoMqAAAAAKJtWb4mLxmPJ2qp8bhc_vro_TNk"
            onChange={(token) => setRecaptchaToken(token)}
            className="self-center my-2"
          />
          <div className="flex flex-row gap-2 self-center">
            <input
              type="checkbox"
              className="checkbox"
              checked={ischecked}
              onChange={() => setIsChecked(!ischecked)}
            />
            <p>
              I agree to the Privacy Policy and{" "}
              <button
                className="font-semibold ml-1"
                onClick={() =>
                  document.getElementById("my_modal_2").showModal()
                }
              >
                Terms of Use
              </button>
            </p>
          </div>
          <dialog id="my_modal_2" className="modal">
            <div className="modal-box">
              <PolicyTermofUse />
            </div>
            <form method="dialog" className="modal-backdrop">
              <button>close</button>
            </form>
          </dialog>
          <button
            className="btn w-full mt-3"
            onClick={handleOnRegister}
            disabled={!recaptchaToken || !ischecked || !isPasswordValid}
          >
            Register
          </button>
        </div>
      </div>
      <div
        className="hidden lg:block h-full bg-primary"
        style={{ width: "calc(100% - 500px)" }}
      ></div>
    </div>
  );
};

export default Register;
