import React from "react";

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white text-center py-4">
        <p>&copy; 2024 Husom App. All rights reserve</p>
        </footer>
    );
    }

export default Footer;