import React from 'react'
import {BASE_IMAGE_URL} from "../constants/url"
import logo from "../assets/logo.svg";

const PropertyCard = (props) => {
const propertyDetaildata = props.propertyDetail;
const onClick = props.onClick ;
const tenantInitial =(tenantFirstname,tenantSurname) =>{
 const tenantFinitial = tenantFirstname ? tenantFirstname.charAt(0).toUpperCase() : '';
 return tenantFinitial;
}


const getImageUrl = () =>{
  if (propertyDetaildata.Images.length === 0) {
  return logo;
  }
  else{
  const imageBaseUrl =  `${BASE_IMAGE_URL}/${propertyDetaildata.PropertyId}/`;
  const imageFilenameUrl =  propertyDetaildata.Images[0].Image.Filename;
  return imageBaseUrl + imageFilenameUrl;
  }
 }



  return (
    <div className="card bg-base-100 w-[340px] h-80 shadow-xl hover:border-2 border-gray-500" onClick={onClick}>
      <div className="flex items-center p-4">
        <div className="bg-white rounded-full p-2 shadow-md flex items-center justify-center w-10 h-10">
          <span className="text-xl font-bold">
             {tenantInitial(propertyDetaildata.LandlordName)}
          </span>
        </div>
        <span className="ml-2 font-bold">{propertyDetaildata.LandlordName} </span>
      </div>
      <figure className="relative">
        <img
          src={getImageUrl()}
          alt={"Exterior Front"}
          className="w-full h-40 object-cover"
        />
      </figure>
      <div className="card-body p-4 property-card-body">
        <h2 className="card-title text-lg font-bold">${parseInt(propertyDetaildata.Price)}/mo</h2>
        <p className="text-sm">{propertyDetaildata.PropertyType} {propertyDetaildata.Bedrooms} bedroom(s) </p>
        <p className="text-sm"> <b> · </b> Availability: {propertyDetaildata.Availability} <b> · </b>Postcode: {propertyDetaildata.PostCode}</p>
        {/* <p className="text-sm">{propertyDetaildata.PostCode}</p>  */}
      </div>
    </div>
  );
}

export default PropertyCard